// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

html {
	// font-size: 12px;
	:not([dir="rtl"]) .sidebar {
		margin-left: 0 !important;
	}
}

.sidebar {
	.nav-title {
		padding: .25rem 1rem;
		background-color: transparentize($black, .6);
	}

	.nav-title.NavTitleSearch {
		padding: 0;
	}

	.nav-item .nav-item .nav-link {
		padding: .5rem 2rem;
	}

	@media screen and (max-width: 991.98px) {
		position: relative !important;
	}
}

.btn-group-xs {
	justify-content: stretch;
}

.btn-group-xs > .btn, .btn-xs {
	padding: $spacer * .25;
	font-size: 90%;
}

.label-switch {
	display: inline-flex;
	align-items: center;

	> .switch {
		margin: 0 $spacer * .5;
	}
}

.mh-0 {
	min-height: 0;
}

.no-bullets {
	list-style:none;
}

.ReactTags__tags {
	position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
	width: 200px;
	border-radius: 2px;
	display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	height: 31px;
	margin: 0;
	font-size: 12px;
	width: 100%;
	border: 1px solid #eee;
	padding: 0 4px;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
	border: 1px solid #ddd;
	background: #eee;
	font-size: 12px;
	display: inline-block;
	padding: 8px;
	margin: 5px 5px;
	cursor: move;
	border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}
.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: .05em .01em .5em rgba(0,0,0,.2);
	background: white;
	width: 200px;
}
.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}
.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  padding-right: 0;
}
