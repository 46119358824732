/* Variables */

@import "~bootstrap/scss/functions";

$orange: #fd7e14;

$navbar-brand-width: 255px;
$navbar-height: 90px;
$sidebar-width: 300px;

/* @coreui variables (includes bootstrap variables) */
@import "~@coreui/coreui/scss/variables";

$theme-colors: map-merge(
  (
	"orange": $orange,
	"pink": $pink,
	"purple": $purple,
	"teal": $teal,
  ),
  $theme-colors
);
