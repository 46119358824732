// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~@fortawesome/fontawesome-free/css/all.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';

@import '~react-jsonschema-form-validation/dist/react-jsonschema-form-validation.min.css';

// Import Main styles for this application
@import './styles/styles.scss';
